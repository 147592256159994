
const initialState = {
    location: 'Noida', // Default location
  };
  
  export const locationReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_LOCATION':
        return {
          ...state,
          location: action.payload,
        };
      default:
        return state;
    }
  };